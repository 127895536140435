




































































































































































































































import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BModal,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import Cleave from "vue-cleave-component";
import { apostaGanha2AviatorStrategiesCollection, db } from "@/@core/firebase";
import {
  Strategy,
  StrategySequence,
  StrategyEnvironment,
} from "./useCrashStrategies";

const components = {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  AppCollapse,
  AppCollapseItem,
  BModal,
  BRow,
  BCol,
  BFormCheckbox,
  Cleave,
} as any;

export default defineComponent({
  components: components,
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        sequences: [],
        targets: [],
      }),
    },
    mode: {
      type: String,
      default: "new",
    },
    modal: {
      type: Object,
      required: false,
      default: () => {},
    },
    env: {
      type: String,
      required: false,
      default: StrategyEnvironment.Production,
    },
  },
  setup(props: any, { emit, root }: any) {
    const strategy = ref(props.value) as Ref<Strategy>;
    const modalStrategy = ref(null);

    const addSequence = () => {
      strategy.value.sequences.push({
        moreThen: "",
        lessThen: "",
        lessThenActive: false,
        moreThenActive: false,
        rules: [],
      });
    };

    const options = {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
    };

    const addSequenceRule = (index: number) => {
      strategy.value.sequences[index].rules.push({
        operator: "=",
        position: 0,
        relative: false,
      });
    };

    const addTargetCondition = () => {
      strategy.value.targets.push({
        condition: "",
        value: "",
        target: "",
      });
    };

    const getSequencesList = (index: number) => {
      const positions = [];
      for (let i = 0; i < index; i++) {
        positions.push({
          value: i + 1,
          text: `#${i + 1}`,
        });
      }
      return positions;
    };

    const saveStrategy = () => {
      const data = {
        name: strategy.value.name,
        createdAt: new Date(),
        sequences: strategy.value.sequences,
        targets: strategy.value.targets,
        enabled: strategy.value.enabled,
        gales: strategy.value.gales,
        env: props.env,
      };
      if (props.mode === "new") {
        addDoc(apostaGanha2AviatorStrategiesCollection, data);
      } else {
        setDoc(
          doc(apostaGanha2AviatorStrategiesCollection, `${strategy.value.id}`),
          data,
          {
            merge: true,
          }
        );
      }

      return false;
    };

    onMounted(() => {
      emit("update:modal", modalStrategy.value);
    });

    return {
      strategy,
      addSequence,
      addSequenceRule,
      getSequencesList,
      addTargetCondition,
      modalStrategy,
      saveStrategy,
      options,
    };
  },
});
