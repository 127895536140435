






































































import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BModal,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import useEnvironment from "@/domain/services/useEnvironment";
import EnvironmentEntity from "@/domain/entities/EnvironmentEntiry";

const components = {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  AppCollapse,
  AppCollapseItem,
  BModal,
  BRow,
  BCol,
  BFormCheckbox,
} as any;

export default defineComponent({
  components: components,
  props: {
    value: {
      type: Object,
      required: true,
    },
    modal: {
      type: Object,
      required: true,
    },
    env: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  setup(props: any, { emit }) {
    const modalRef = ref(null);
    const data = ref<EnvironmentEntity>(props.value);
    const { saveEnvironment } = useEnvironment("apostaGanha2");

    onMounted(() => {
      emit("update:modal", modalRef.value);
    });

    const save = () => {
      const updateData = {
        activated: data.value.activated ?? false,
        botKey: data.value.botKey,
        chatId: data.value.chatId,
        mode: data.value.mode,
      };

      saveEnvironment(props.mode, data.value.id, updateData);
      emit("updated", data.value);
    };

    return {
      modalRef,
      data,
      save,
    };
  },
});
