import { apostaGanha2AviatorStrategiesCollection } from "@/@core/firebase"
import { ref } from "@vue/composition-api"
import { BIconArrowUpSquareFill } from "bootstrap-vue"
import { onSnapshot, query, Timestamp, where } from "firebase/firestore"

export type StrategySequence = {
  moreThen: string
  lessThen: string
  moreThenActive: boolean
  lessThenActive: boolean
  rules: {
    operator: string
    position: number
    relative: boolean
  }[]
}

export type Strategy = {
  id?: string
  name: string
  enabled: boolean
  createdAt: string
  sequences: StrategySequence[]
  gales: number
  targets: Array<any>
}

export enum StrategyEnvironment {
  Production = "Production",
  Aviator10 = "Aviator10",
  EDS = "EDS",
  EDS10 = "EDS10"
}

export const envToText = (env: StrategyEnvironment) => {
  if (env == StrategyEnvironment.Production) return "Produção"
  if (env == StrategyEnvironment.Aviator10) return "Aviator 10"
  if (env == StrategyEnvironment.EDS) return "EDS"
  if (env == StrategyEnvironment.EDS10) return "EDS 10"

  return env
}

export function getStrategies(
  env: StrategyEnvironment = StrategyEnvironment.Production
) {
  const q = query(apostaGanha2AviatorStrategiesCollection, where("env", "==", env))
  const strategies = ref([])
  const unsub = onSnapshot(q, (querySnapshot) => {
    strategies.value.splice(0, strategies.value.length)
    querySnapshot.forEach((strategyDoc) => {
      const createdAt = (strategyDoc.data().createdAt as Timestamp).toDate()
      const strategy: Strategy = {
        id: strategyDoc.id,
        name: strategyDoc.data().name,
        enabled: strategyDoc.data().enabled,
        createdAt: new Date(createdAt).toISOString(),
        gales: strategyDoc.data().gales ? strategyDoc.data().gales : 2,
        sequences: strategyDoc.data().sequences.map((sq: any) => {
          if (!sq.numbers) sq.numbers = []
          return sq
        }),
        targets: strategyDoc.data().targets,
        greenCount: strategyDoc.data().greenCount || 0,
        lossCount: strategyDoc.data().lossCount || 0,
      } as Strategy
      strategies.value.push(strategy as never)
    })
  })

  return strategies
}

export const tableColumns = [
  { key: "name", sortable: true, label: "Nome" },
  { key: "enabled", sortable: true, label: "Ativo" },
  { key: "greenCount", sortable: true, label: "Greens" },
  { key: "lossCount", sortable: true, label: "Loss" },
  { key: "hit", sortable: true, label: "Acerto" },
  { key: "createdAt", sortable: true, label: "Criado em" },
  { key: "actions", label: "Ações" },
]
